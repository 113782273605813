<i18n lang="yaml">
  en:
    pressKeyToAction: '{press} {key} {to} {action}'
    press: 'Press'
    to: 'to'
  pt:
    pressKeyToAction: '{press} {key} {to} {action}'
    press: 'Pressione'
    to: 'para'
</i18n>

<template>
  <i18n-t
    :style="cssProps"
    tag="p"
    keypath="pressKeyToAction"
    class="deck-kbd"
  >
    <template
      v-if="hasLabel"
      #press
    >
      <span class="sr-only">
        {{ t('press') }}
      </span>
    </template>

    <template #key>
      <div class="deck-kbd__command">
        <template v-for="(keys, index) in commands">
          <kbd
            v-if="keys.length === 1"
            :key="`${index}-singlekey`"
            class="deck-kbd__key"
          >
            {{ keys[0] }}
          </kbd>
          <kbd
            v-else
            :key="`${index}-multikey`"
            class="deck-kbd__key"
          >
            <kbd
              v-for="(key, indexMultiKey) in keys"
              :key="indexMultiKey"
            >
              {{ key }}
            </kbd>
          </kbd>
        </template>
      </div>
    </template>

    <template
      v-if="hasLabel"
      #to
    >
      <span class="sr-only">
        {{ t('to') }}
      </span>
    </template>

    <template
      v-if="hasLabel"
      #action
    >
      <span class="deck-kbd__label">
        <!-- @slot Render content for the label instead of using its prop -->
        <slot>
          {{ label }}
        </slot>
      </span>
    </template>
  </i18n-t>
</template>

<script lang="ts">
import { isMac } from '~/assets/javascript/utils';
import { TRANSLUCENT_BLACK } from '../utils/color';

export default defineComponent({
  name: 'DeckKbd',
  props: {
    /**
     * A keyboard key or key command where is expected to be concatenated by "+"
     * signs.
     * Eg: 'cmd+p', 'alt+p+o', 'esc', 'm', 'left', etc.
     *
     * You can group a set of commands related to a singular action by
     * separating them with spaces, which will render each command separately
     * for a single label.
     * Eg: 'up down left right' for navigating, 'cmd+shift+close esc' for closing, etc.
     *
     * @type {string}
     * @required
     */
    command: {
      type: String,
      required: true,
    },

    /**
     * A label to be rendered after the key.
     * @type {string}
     * @default ''
     */
    label: {
      type: String,
      default: '',
    },

    /**
     * When presenting a label, force it to be rendered at a block level above the key.
     * @type {boolean}
     * @default false
     */
    vertical: {
      type: Boolean,
      default: false,
    },

    /**
     * Render the key in light color for contrast on dark background.
     * @type {boolean}
     * @default false
     */
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      t: useI18n().t,
    };
  },
  computed: {
    hasLabel() {
      return this.label || this.$slots.default;
    },

    commands() {
      return this.command
        .split(' ') // Commands related to the same action
        .map(command => command.split('+') // Each key of the command
          .map(key => this.getKeyLabel(key))); // Map each key to its label
    },

    isMac() {
      return isMac();
    },

    cssProps() {
      return {
        '--deck-kbd-color': this.dark ? TRANSLUCENT_BLACK : 'var(--z-theme-surface)',
        '--deck-kbd-background-color': this.dark ? 'var(--z-theme-surface)' : 'var(--z-theme-text)',
        '--deck-kbd-label-color': this.dark ? 'var(--z-theme-text)' : TRANSLUCENT_BLACK,
        '--deck-kbd-flex-direction': this.vertical ? 'column-reverse' : 'row',
      };
    },
  },
  methods: {
    getKeyLabel(key) {
      const keyMap = new Map([
        ['cmd', this.isMac ? '⌘' : 'Ctrl'],
        ['command', this.isMac ? '⌘' : 'Ctrl'],

        ['option', '⌥'],
        ['opt', '⌥'],

        ['alt', 'Alt'],

        ['ctrl', 'Ctrl'],
        ['control', 'Ctrl'],

        ['shift', 'Shift'],

        ['esc', 'Esc'],
        ['escape', 'Esc'],

        ['enter', '↵'],
        ['return', '↵'],

        ['space', 'Space'],

        ['tab', 'Tab'],

        ['backspace', '⌫'],

        ['delete', 'Delete'],
        ['del', 'Delete'],

        ['home', 'Home'],

        ['end', 'End'],

        ['pgup', 'Page Up'],

        ['pageup', 'Page Up'],

        ['pgdn', 'Page Down'],

        ['pagedown', 'Page Down'],

        ['left', '←'],
        ['right', '→'],
        ['up', '↑'],
        ['down', '↓'],
      ]);

      return keyMap.has(key.toLowerCase()) ? keyMap.get(key.toLowerCase()) : key.toUpperCase();
    },
  },
});
</script>

<style lang="scss">
.deck-kbd {
  display: inline-flex;
  flex-direction: var(--deck-kbd-flex-direction);
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  gap: 4px;
  margin-bottom: 0 !important; // p override
  color: var(--deck-kbd-label-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.4px;
}

.deck-kbd__command {
  display: flex;
  gap: 2px;
}

kbd.deck-kbd__key {
  --key-height: 20px;

  height: var(--key-height);
  min-width: var(--key-height);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px 2px;
  padding-inline: 4px !important;
  background-color: var(--deck-kbd-background-color) !important;
  color: var(--deck-kbd-color) !important;
  font: inherit !important;
  letter-spacing: inherit;
  border-radius: 4px;
}
</style>
